import "./../App.css";
import { Container, Row, Col, Image } from "react-bootstrap";
import { useState, useEffect } from "react";
import horse from "./../images/trippy-horse.gif";
import { Link } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getFirestore, doc, collection, getDocs, getDocFromCache } from "firebase/firestore";
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";


// Your web app's Firebase configuration
// const firebaseConfig = {
//     apiKey: "AIzaSyC-XvKZXKbxpNu6_767XS61RmolaeetpAs",
//     authDomain: "horse-data-33f97.firebaseapp.com",
//     projectId: "horse-data-33f97",
//     storageBucket: "horse-data-33f97.firebasestorage.app",
//     messagingSenderId: "802874176319",
//     appId: "1:802874176319:web:e03c18a23766e65605598a"
//   };

//   // Initialize Firebase
//   const app = initializeApp(firebaseConfig);

// const auth = getAuth();
// const db = getFirestore(app);
// window.recaptchaVerifier = new RecaptchaVerifier(auth, 'sign-in-button', {
//   'size': 'invisible',
//   'callback': (response) => {
//     // reCAPTCHA solved, allow signInWithPhoneNumber.
//     onSignInSubmit();
//   }
// });

// auth.languageCode = 'it';



  
// const docRef = doc(db, "prompts", "d6prpFT2uS8U7W5NILIT");

// // Get a document, forcing the SDK to fetch from the offline cache.
// try {
//   const doc = await getDocFromCache(docRef);

//   // Document was found in the cache. If no cached document exists,
//   // an error will be returned to the 'catch' block below.
//   console.log("Cached document data:", doc.data());
// } catch (e) {
//   console.log("Error getting cached document:", e);
// }
  



// const phoneNumber = getPhoneNumberFromUserInput();
// const appVerifier = window.recaptchaVerifier;

// function getPhoneNumberFromUserInput() {
//     return "5088876954"
// }

// function getCodeFromUserInput() {
//     return "123456"
// }

// function onSignInSubmit() {
//     signInWithPhoneNumber(auth, phoneNumber, appVerifier)
//     .then((confirmationResult) => {
//       // SMS sent. Prompt user to type the code from the message, then sign the
//       // user in with confirmationResult.confirm(code).
//       window.confirmationResult = confirmationResult;
//       // ...
//     }).catch((error) => {
//       // Error; SMS not sent
//       // ...
//     });

//     const code = getCodeFromUserInput();
//     window.confirmationResult.confirm(code).then((result) => {
//       // User signed in successfully.
//       const user = result.user;
//       // ...
//     }).catch((error) => {
//       // User couldn't sign in (bad verification code?)
//       // ...
//     });

// }

let activities = [
    {prompt: "A piece of glass you have blown yourself.", points: "5"},
    {prompt: "A piece of clay you have spun yourself.", points: "5"},
    {prompt: "A homemade candle you have made yourself.", points: "5"},
    {prompt: "Proof that you have signed up for a class. The class must be at least 1 hour in length. +1 point for every two weeks the class runs. e.g. a four week class would be worth 7, points.", points: "5"},
    {prompt: "A hand signed petition of something you feel strongly about. This must have 5 or more signatures.", points: "4"},
    {prompt: "A picture of you partaking in some winter sport. This includes snowboarding, skiing, or ice skating.", points: "3"},
    {prompt: "An honest review (1 paragraph of >6 sentences) of an album you found in a record shop.", points: "3"},
    {prompt: "An honest review (1 paragraph of >6 sentences) of a book you have finished. You may be, at most, 60% finished with this book prior to competiton. +1 point if you post this review to Goodreads. +2, points if you create a Goodreads to post this to.", points: "3"},
    {prompt: "A handwritten poll of something you feel strongly about. This must have 5 or more responses.", points: "3"},
    {prompt: "One ticket to the symphony or a musical event.", points: "3"},
    {prompt: "Proof of an entire puzzle you have completed. You may be, at most, 10% finished with this puzzle prior to competiton.", points: "3"},
    {prompt: "A photograph of your name written in a board game box, because you won that board game.", points: "3"},
    {prompt: "A strava screenshot of a 2+ mile run.", points: "2"},
    {prompt: "A picture of a 3+ mile indoor run.", points: "2"},
    {prompt: "A selfie of you enjoying some soft serve.", points: "2"},
]

let physical_items = [
    {prompt: "One baked good, pastry or bagel you have made yourself. Photographic evidence will count.", points: "3"},
    {prompt: "A hand crocheted/knitted/embroidered little guy. +3 points if you did not previously know how to crochet/knit/embroider.", points: "3"},
    {prompt: "A piece of origami consisting of 8+ folds. Folds must be decisive and the end product must resemble something.", points: "3"},
    {prompt: "One piece of printed media(a poster, book, or textile).", points: "3"},
    {prompt: "Four sentences hand written in a language you did not speak prior to the competition.", points: "3"},
    {prompt: "A painting you have created.", points: "2"},
    {prompt: "A picture of a plant you have acquired. +1 point for every week this plant has lived in your custody before the judgement day.", points: "1"},
    {prompt: "A screenshot of a location you are at very far away from Seattle. The furthest location from Seattle wins two points, the second place location wins one.", points: "2"},
]

let acquired_goods = [
    {prompt: "A miniature horse (open to interpretation).", points: "2"},
    {prompt: "A healing crystal from any crystal store.", points: "2"},
    {prompt: "A rock from a beach.", points: "2"},
    {prompt: "A selfie with somebody that has more than 200 instagram followers.", points: "2"},
    {prompt: "An inch of Seattle rain water.", points: "2"},
    {prompt: "One piece of thrifted witch or wizard apparel.", points: "2"},
    {prompt: "One item from Archie Mcphee.", points: "2"},
]

function Horse() {

    // Get a list of cities from your database
    async function getPrompts(db) {
        const promptsCol = collection(db, 'prompts');
        console.log(promptsCol)
        const promptsSnapshot = await getDocs(promptsCol);
        console.log(promptsSnapshot)
        const qList = promptsSnapshot.docs.map(doc => doc.data());

        setPrompts(qList)
        return qList;
    }

    const [prompts, setPrompts] = useState([]);

    useEffect(() => {
        // Load data once from db.
        //let prompts = getPrompts(db)
    }, [])

return (
    <div>
        <Image rounded className="w-25 d-block  mx-auto" src={horse} />
      <Container
        id="home"

      >
        <Row>
          <Col
            className="text-center mx-auto align-items-center justify-content-center"
            xs={{ span: 12, offset: 0 }}
          >
            <h1 className="display-3">SAD HORSE</h1>
            <hr
              style={{
                height: "1px",
                border: "1px solid white",
                color: "#fff",
              }}
            />
            <p>
            Dark times approach the pacific northwest. More than the sun setting early, a curse has been cast upon the land. The dark wizard Noah Va has cursed the Pacific Northwest with darkness so wild horses 🐎 cannot run free unless a concoction is made to warm the hearts and souls of the sleepy Seattlites.
            </p>
            <p>
            Contestants will have until January 1, 2025 to complete as few or many of the following as possible. The more goals challengers complete, the stronger the potion will be to claim one wild Kamala Horse.
            Around January 1st, a meeting will take place to see <a href="https://www.youtube.com/watch?v=R_FQU4KzN7A">whose potion is the strongest</a>.
            </p>
            <hr />
            {prompts.map((e,idx) => (
                <p>{Object.values(e)}</p>
            ))}

          </Col>
        </Row>
      </Container>
      <Container
        className="mx-auto"
        fluid
      >
        <Col xs={{ span: 12, offset: 0 }} md={{span: 6, offset: 3}}>
        <Row>
            <Col xs={12}>
                <h2 className="display-3">Activities</h2>
                {activities.map((e,idx) => (
                    <Row className="d-flex mx-2">
                        <h5 className="text-left">{e.prompt}</h5>
                        <h5 className="text-right"><b>{e.points} points</b></h5>
                        <hr/>
                    </Row>
                ))}
            </Col>
        </Row>
        <Row>
            <Col xs={12}>
                <h2 className="display-3">Handmade Items</h2>
                {physical_items.map((e,idx) => (
                    <Row className="d-flex mx-2">
                    <h5 className="text-left">{e.prompt}</h5>
                    <h5 className="text-right"><b>{e.points} points</b></h5>
                    <hr/>
                </Row>
                ))}
            </Col>
        </Row>
        <Row>
            <Col xs={12}>
                <h2 className="display-3">Acquired Goods</h2>
                {acquired_goods.map((e,idx) => (
                    <Row className="d-flex mx-2">
                    <h5 className="text-left">{e.prompt}</h5>
                    <h5 className="text-right"><b>{e.points} points</b></h5>
                    <hr/>
                </Row>
                ))}
            </Col>
        </Row>
        </Col>
      </Container>

    </div>
)}

export default Horse;

