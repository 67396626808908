import {
  Row,
  Image,
  Col,
  Stack,
} from "react-bootstrap";
import "./App.css";
import me from "./images/me.jpg";
import {  ChevronDown } from 'react-bootstrap-icons';


function AboutMe() {
  return (
    <Row>
      <h1 className="display-3 mb-5">About Me</h1>
      <Row>
        <Col xs={12} md={6}>
          <Stack gap={0}>
            <Image rounded className="w-75 d-block mx-auto" src={me} />
            <a
              className="w-100 text-decoration-none text-center"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.google.com/maps/@55.2711888,14.755407,3a,75y,356.52h,89.06t/data=!3m8!1e1!3m6!1sAF1QipMfEXn6g00BuasK5IaZFJ3lFj7OeOn5juct8gkn!2e10!3e11!6shttps:%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipMfEXn6g00BuasK5IaZFJ3lFj7OeOn5juct8gkn%3Dw203-h100-k-no-pi-0-ya61.163376-ro-0-fo100!7i12600!8i6300?entry=ttu">
              source
            </a>
          </Stack>
        </Col>
        <Col md={6} className="d-flex justify-content-start">
          <Stack>
            <p>
              I'm a Software Engineer living in Seattle, Washington. I started
              programming at an early age, and like to learn new things. Some of my
              other hobbies include:
            </p>
            <br />
            <ul>
              <h4>Hiking 🥾</h4>
              <h4>Travel 🗺️</h4>
              <h4>Music 🎹</h4>
              <h4>Philosophy 📖</h4>
            </ul>
          </Stack>
        </Col>
      </Row>
      <Row>
        <a href="#portfolio"><ChevronDown title="Portfolio" className="d-block mx-auto mt-5" style={{color: "black"}} /></a>
      </Row>
    </Row>
  );
}

export default AboutMe;
