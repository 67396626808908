import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import './App.css';

function Navigation() {


  const location = useLocation()

  useEffect(() => {
    // Scroll to the element with the ID from the fragment identifier
    if (location.hash) {
      const element = document.querySelector(location.hash)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [location.hash])

  return (
    <Navbar sticky="top" id="navbar" background-color='rgba(0,0,0,0)' expand="md" className="bg-body-tertiary" data-bs-theme="dark">
      <Container>
        <Link className='text-decoration-none' to={{pathname: "/"}}><Navbar.Brand>tjh.town</Navbar.Brand></Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to={{pathname: '/', hash: '#home'}}>Home</Nav.Link>
            <Nav.Link as={Link} to={{pathname: '/', hash: '#aboutme'}}>About</Nav.Link>
            {/* <Nav.Link href="#travel">Travel</Nav.Link> */}
            <Nav.Link as={Link} to={{pathname: '/', hash: '#portfolio'}}>Portfolio</Nav.Link>
            <Nav.Link as={Link} to={{pathname: '/horse'}}>Horse</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;
