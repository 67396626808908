import React from "react";
import { Row, Col, Card, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ArrowDown, BoxArrowUpRight } from "react-bootstrap-icons";

function ScrollResource(props) {
  return (
    <>
      <Col>
        <Card.Title className="mb-0">{props.title}</Card.Title>
        <cite>{props.date}</cite>
        {props.description && (
          <Card.Text>{props.description}</Card.Text>
        )}

        <Row>
          {props.link && props.external && (
            <Col className="text-nowrap">
              <a
                href={props.link}
                className="text-decoration-none"
                target="_blank"
                rel="noopener noreferrer"
              >
                View{" "}
                <BoxArrowUpRight className="d-inline-flex pb-1 justify-content-center" />
              </a>
            </Col>
          )}
          {props.link && !props.external && (
            <Col className="text-nowrap">
              <Link
                to={{ pathname: props.link }}
                className="text-decoration-none"
              >
                View{" "}
                <BoxArrowUpRight className="d-inline-flex pb-1 justify-content-center" />
              </Link>
            </Col>
          )}
          {props.customLink && (
            <Col className="text-nowrap">
              <a
                href={props.customLink.link}
                className="text-decoration-none"
                target="_blank"
                rel="noopener noreferrer"
              >
                {props.customLink.description}{" "}
                <BoxArrowUpRight className="d-inline-flex pb-1 justify-content-center" />
              </a>
            </Col>
          )}
          {
            // Optional inclusion of download button for resource
            props.download ? (
              <Col className="text-nowrap">
                <Link
                  to={{ pathname: props.link }}
                  target="_blank"
                  download={props.download}
                >
                  Download <ArrowDown />
                </Link>
              </Col>
            ) : null
          }
        </Row>
      </Col>
      <Col xs={12} md={6}>
        {props.thumbnail && (
          <Image rounded className="w-75 p-3" src={props.thumbnail} />
        )}
      </Col>
    </>
  );
}

export default ScrollResource;
