import React from 'react';
import { Row, Col, Card, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ArrowDown, BoxArrowUpRight } from 'react-bootstrap-icons';

function Resource(props) {

return (
<Card style={{ maxWidth: '17rem'}} className="h-auto px-1 text-center my-3 border-0 border-bottom">
<Card.Body>
  <Card.Title className="mb-0">{props.title}</Card.Title>
  <cite>{props.date}</cite>

  <Row className="mx-auto">
  {(props.link && props.external) &&
      <Col className='text-nowrap'>
          <a href={props.link} className='text-decoration-none' target="_blank" rel='noopener noreferrer'>View <BoxArrowUpRight className='d-inline-flex pb-1 justify-content-center'/></a>
      </Col>
  }
  {(props.link && !props.external) &&
      <Col className='text-nowrap'>
          <Link to={{pathname: props.link}} className='text-decoration-none'>View <BoxArrowUpRight className='d-inline-flex pb-1 justify-content-center'/></Link>
      </Col>
  }
  {props.customLink &&
      <Col className='text-nowrap'>
          <a href={props.customLink.link} className='text-decoration-none' target="_blank" rel='noopener noreferrer'>{props.customLink.description} <BoxArrowUpRight className='d-inline-flex pb-1 justify-content-center'/></a>
      </Col>
  }
  {	// Optional inclusion of download button for resource
      props.download ? (
      <Col className='text-nowrap'>
          <Link to={{pathname: props.link}} target="_blank" download={props.download}>Download <ArrowDown/></Link>
      </Col>

  ) : null}
  </Row>
  {props.thumbnail &&
    <Image
    rounded
    className='w-75'
    src={props.thumbnail} />
  }
  {props.description &&
    <Card.Text className="text-center">
    {props.description}
    </Card.Text>
  }

</Card.Body>
</Card>

)}

export default Resource;