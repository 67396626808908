
import './App.css';
import Navigation from './Navigation.jsx';
import Home from './Home.jsx'
import Horse from './horse/Horse.jsx'


import { Route, Routes } from 'react-router-dom';

import Mountains from "./projects/Mountains.jsx";
import Conway from "./projects/Conway.jsx";
import StarWars from "./projects/StarWars.jsx"

function App() {

return (
  <>
    <Navigation className='pos-rel' />
    <Routes>
          {/* <Route path='/' element={<Snow style={{ "backgroundColor": "black" }} />} /> */}  
          <Route path='/conway' element={<Conway style={{ "backgroundColor": "black" }} />} />
          {/* <Route path='/sound' element={<Sound style={{ "backgroundColor": "black" }} />} /> */}
          <Route path='/sw' element={<StarWars />} />
          <Route path='/mountains' element={<Mountains />} />
          <Route path='/horse' element={<Horse />} />
          <Route index path='/' element={<Home />} />
      </Routes>
  </>
)}

export default App;